<template>
    <div class="mx-3">
        <h2 class="px-5 py-5">{{ $t('Details Loan') }}</h2>

        <v-row class="py-5 px-10 justify-space-arround align-center"> 
      <v-col md="3">
        <h4>{{ $t("hr.time.emp-name") }}</h4>
        <h2>{{ tableItems.employee_name }}</h2>
      </v-col>
      <v-col md="3">
        <h4>{{ $t("hr.loan.Name") }}</h4>
        <h2>{{ tableItems.loan_name }}</h2>
      </v-col>
      <v-col md="3">
        <h4>{{ $t("hr.loan.Value") }}</h4>
        <h2>{{ tableItems.value }}</h2>
      </v-col>
      <v-col md="3">
        <h4>{{ $t("Loan Date") }}</h4>
        <h2>{{ tableItems.date }}</h2>
      </v-col>
      <v-col md="3">
        <h4>{{ $t("hr.loan.Total Payments Number") }}</h4>
        <h2>{{ tableItems.total_payments_number }}</h2>
      </v-col>
      <v-col md="3">
        <h4>{{ $t("hr.loan.Rest Payments") }}</h4>
        <h2>{{ tableItems.rest_payment }}</h2>
      </v-col>
      <v-col md="3">
        <h4>{{ $t("hr.loan.Loan Note") }}</h4>
        <h2>{{ tableItems.note }}</h2>
      </v-col>
      <v-col md="3">
        <h4>{{ $t("hr.loan.Document Note") }}</h4>
        <h2>{{ tableItems.document_note }}</h2>
      </v-col>
    </v-row>
     
     
      <v-data-table :dark="$store.state.isDarkMode"
        :headers="headers"
        :loading="loading"
        :items="tableItems.payments_detail"
        class="elevation-1"
        hide-default-footer
        :items-per-page="10"
      >
   
      </v-data-table>

      
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import i18n from '@/i18n';
  import debounce from "../../../../../helpers/debounce";

import { saveAs } from 'file-saver';

  
  export default {
    components: {    },
    computed:{
 
    },
    data: () => ({
      
      
      tableItems: null,
     
      loading: false,
      page: 1,
      total: 1,
      headers: [  
        { text: i18n.t("Installment Date"), value: "date" },
        { text: i18n.t('payment value'), value: "value" },
        
      ],
    }),
    methods: {
     
 
   
      async getAll(page) {
        try {
          
          if (!page) this.page = 1;
         
          this.loading = true;
          
          const res = await axios.get(`hr/report/employee-loan/${this.$route.params.id}`);
        
            this.tableItems = res.data.data;
           
          
          this.total = res.data.total;
        } finally {
          this.loading = false;
        }
      },
    
      
 
    },
    created() {
      this.getAll();
    },
  };
  </script>
  
  <style></style>
  